import { http } from '@/http/axios.js'

// 列表
export function getListAPI(data) {
  return http({
    url: `/api/manage/user/pay_voucher/page`,
    method: 'POST',
    data
  })
}


export function getDetailAPI(id) {
  return http({
    url: `/api/manage/user/pay_voucher/detail`,
    method: 'post',
    data: { id }
  })
}


export function auditAPI(data) {
  return http({
    url: `/api/manage/user/pay_voucher/audit`,
    method: 'post',
    data
  })
}

export function uploadInvoiceAPI(data) {
  return http({
    url: `/api/manage/user/invoice/uploadInvoice`,
    method: 'post',
    data
  })
}

