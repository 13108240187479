<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form
          ref="form"
          inline
          :model="searchData"
          label-width=""
      >
        <el-form-item label="手机号码">
          <el-input
              size="small"
              v-model="searchData.mobile_like"
              placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单时间：">
          <el-date-picker
              style="width: 240px"
              v-model="searchData.created_at_between"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getList"
          >搜索
          </el-button>
          <el-button
              type="info"
              size="mini"
              icon="el-icon-refresh"
              @click="reset"
          >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', searchData.status === '' ? 'active' : '']" @click="handleClickTab('')">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">拒绝</span>
        </el-badge>
      </div>
    </div>

    <el-table :data="list">
      <el-table-column
          prop="pay_file"
          label="支付凭证"
          width="width"
          min-width="60px"
      >
        <template slot-scope="{ row }">
          <div
              class="user-box"
              v-if="row.pay_file"
          >
            <div class="row">
              <a v-for="item in row.pay_file" :key="item.name" :href="item.url" :download="item.name">{{item.name}}</a>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="buyer_remark"
          label="备注"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="status_dsc"
          label="状态"
          width="width"
          min-width="40px"
      >
      </el-table-column>

      <el-table-column
          prop="user"
          label="用户"
          width="width"
          min-width="60px"
      >
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row">
              <span>名称：</span>
              <span>{{ row?.user?.nickname }}</span>
            </div>
            <div class="row">
              <span>号码：</span>
              <span>{{ row?.user?.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>


      <el-table-column
          prop="created_at"
          label="提交日期"
          width="width"
          min-width="40px"
      ></el-table-column>
      <el-table-column
          label="操作"
          width="150px"
          fixed="right"
      >
        <template slot-scope="{ row }">
          <el-link
              v-if="row.status == 0"
              style="margin: 0 10px"
              type="primary"
              :underline="false"
              @click="showAuditDialog(row)"
          >审核
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :total="total"
        :page.sync="searchData.page"
        :limit.sync="searchData.pagesize"
        @pagination="getList"
    />
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-form ref="form" :model="changeStatusFormData" label-width="80px">
        <el-form-item label="状态：">
          <el-radio-group v-model="changeStatusFormData.status">
            <el-radio :label="20">同意</el-radio>
            <el-radio :label="10">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="changeStatusFormData.status === 20">
          <el-form-item label="支付金额">
            <el-input-number v-model="changeStatusFormData.pay_price" :min="0" :max="10000"
                             step="0.1"></el-input-number>
          </el-form-item>
          <el-form-item label="到期时间">
            <el-date-picker type="date" placeholder="选择日期" v-model="changeStatusFormData.expire_date"
                            style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="子账号数量">
            <el-input v-model="changeStatusFormData.account_num"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="changeStatusFormData.audit_remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="audit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getListAPI, getDetailAPI, auditAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'
import {addRole} from "@/views/powerManage/role/api";

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{required: true, message: '该项不能为空', trigger: 'blur'}],
      show_detail: false,
      show_review: false,
      dialogVisible: false,
      list: [],
      city_list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        mobile_like: '',
        status: '',
        created_at_between: [],
      },
      total: 0,
      changeStatusFormData: {
        id: '',
        status: null,
        audit_remark: '',
        pay_price: 0,
        account_num: 0,
        expire_date: '',
      },
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    // 详情
    async handleDetail({id}) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    handleClose() {
    },
    showAuditDialog({id}) {
      this.changeStatusFormData.id = id
      this.dialogVisible = true
    },
    audit() {
      auditAPI(this.changeStatusFormData).then(() => {
        this.$message.success('审核成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    handleClickTab(status) {
      this.searchData.status = status
      this.getList()
    },
    async getList() {
      // 获取get 参数
      let params = {...this.searchData}
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }

  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}

.user-box {
  .row {
    display: flex;

    span {
      &:nth-child(1) {
        width: 72px;
        text-align: right;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}
</style>
